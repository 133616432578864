<template>
  <v-container id="meta-customer-unique">
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :max-days="1800" :loading="loading" @update="updatePeriod" />
    <location-selector :loading="loading" @update="updateLocations" />
    <v-row>
      <v-col v-for="provider in Object.keys(dataCollection)" :key="provider" xl="6" lg="12" md="12" sm="12">
        <h2 class="mb-2">
          <img :src="require('@/assets/provider/' + provider + '.png')" width="24px" style="vertical-align:middle"> {{ $t('metaReport.metaCrm.provider.' + String(provider)) }}
        </h2>
        <div v-if="Object.keys(dataCollection[provider]).length > 0" class="mediumChart">
          <bar-chart :chart-data="dataCollection[provider]" :options="options" data-position="above" data-color="rgba(0, 0, 0, 1)" />
        </div>
        <div v-else>
          {{ $t('report.noresult') }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { MONTH, getFirstDayOfYearDate, getTodayDate } from '@/util/DateUtil';
  import { COLOR_RANDOM } from '@/util/ColorUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';
  import LocationSelector from '@/views/components/common/LocationSelector';
  import BarChart from '@/views/components/common/chart/BarChart';

  export default {
    name: 'MetaCustomerUniqueAnalytics',
    components: { LocationSelector, PeriodSelector, BarChart },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getFirstDayOfYearDate(-4),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        locations: null,
        dataCollection: {},
        options: {
          title: {
            display: false
          },
          legend: {
            display: true,
            position: 'bottom',
            align: 'center'
          },
          tooltips: {
            mode: 'index',
            intersect: false
          },
          responsive: true,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                min: 0
              }
            }]
          }
        }
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null || this.locations === null) {
          return;
        }

        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/meta/customer/unique?from='
            + encodeURIComponent(this.startFullDate) + '&to=' + encodeURIComponent(this.endFullDate) + '&locations=' + String(this.locations), { timeout: 60000 });

          if (response.data !== undefined) {
            this.dataCollection = {};
            Object.keys(response.data).forEach((provider) => {
              this.dataCollection[provider] = {
                labels: MONTH.slice(1),
                datasets: []
              };
              Object.keys(response.data[provider]).forEach((year) => {
                this.dataCollection[provider].datasets.push({ label: year, backgroundColor: COLOR_RANDOM[(Number(year) % 2020)], stack: year, data: response.data[provider][year] });
              });
            });
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('metaReport.error'));
        }
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      },
      async updateLocations (locationKeys) {
        this.locations = locationKeys.join(',');
        await this.refreshData();
      }
    }
  };
</script>
