export const COLOR_RANDOM = [
  '#795548', '#6200EA', '#C51162', '#607D8B',
  '#304FFE', '#00BFA5',
  '#9E9E9E', '#AEEA00', '#FF9800', '#0091EA',
  '#2979FF', '#00C853', '#64DD17',
  '#FFAB00', '#FF6D00', '#DD2C00',
  '#f44336', '#E91E63', '#9C27B0',
  '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4',
  '#AA00FF', '#009688', '#4CAF50', '#CDDC39',
  '#FFEB3B', '#FFD600',
  '#673AB7',
  '#D50000',
  '#8BC34A', '#FFC107', '#00B8D4', '#FF5722'
];
