<template>
  <v-container id="crm" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card color="tabBackgroundColor">
      <v-tabs color="deep-blue accent-4" background-color="transparent" centered grow show-arrows @change="onTabChange">
        <v-tab>{{ $t('metaReport.metaCrm.customersByLocation.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaCrm.customerUnique.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaCrm.customerNew.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaCrm.customerInactive.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaCrm.customerRepeat.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaCrm.customerOrder.title') }}</v-tab>
        <v-tab>{{ $t('metaReport.metaCrm.customerAcquisition.title') }}</v-tab>
        <v-btn icon medium dark color="black" :loading="loading" :disabled="loading" @click.stop="refreshCurrentTab">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-tab-item key="1">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-customers-by-location-analytics :loading="loading" :refresh="refresh[0]" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="2">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-customer-unique-analytics :loading="loading" :refresh="refresh[1]" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="3">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-customer-new-analytics :loading="loading" :refresh="refresh[2]" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="4">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-customer-inactive-analytics :loading="loading" :refresh="refresh[3]" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="5">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-customer-repeat-analytics :loading="loading" :refresh="refresh[4]" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="6">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-customer-order-analytics :loading="loading" :refresh="refresh[5]" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="7">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <meta-customer-acquisition-analytics :loading="loading" :refresh="refresh[6]" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import MetaCustomersByLocationAnalytics from '@/views/components/business/analytics/meta/MetaCustomersByLocationAnalytics';
  import MetaCustomerUniqueAnalytics from '@/views/components/business/analytics/meta/MetaCustomerUniqueAnalytics';
  import MetaCustomerNewAnalytics from '@/views/components/business/analytics/meta/MetaCustomerNewAnalytics';
  import MetaCustomerInactiveAnalytics from '@/views/components/business/analytics/meta/MetaCustomerInactiveAnalytics';
  import MetaCustomerRepeatAnalytics from '@/views/components/business/analytics/meta/MetaCustomerRepeatAnalytics';
  import MetaCustomerOrderAnalytics from '@/views/components/business/analytics/meta/MetaCustomerOrderAnalytics';
  import MetaCustomerAcquisitionAnalytics from '@/views/components/business/analytics/meta/MetaCustomerAcquisitionAnalytics';

  export default {
    name: 'MetaCrm',
    components: { AlertNotification, MetaCustomersByLocationAnalytics, MetaCustomerUniqueAnalytics, MetaCustomerNewAnalytics, MetaCustomerInactiveAnalytics, MetaCustomerRepeatAnalytics, MetaCustomerOrderAnalytics, MetaCustomerAcquisitionAnalytics },
    data () {
      return {
        alert: '',
        currentTab: 0,
        loading: false,
        refresh: [null, null, null, null, null, null, null, null, null, null, null, null]
      };
    },
    mounted () {},
    methods: {
      refreshCurrentTab () {
        this.onTabChange(this.currentTab);
      },
      onTabChange (tab) {
        this.loading = true;
        this.refresh[tab] = Date.now();
        this.currentTab = tab;
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      }
    }
  };
</script>
