<template>
  <v-container id="meta-customer-unique">
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :max-days="1800" :loading="loading" @update="updatePeriod" />
    <location-selector :loading="loading" @update="updateLocations" />
    <v-row v-if="Object.keys(dataCollection).length > 0">
      <v-col xl="6" lg="12" md="12" sm="12">
        <div class="mediumChart">
          <bar-chart :chart-data="dataCollection" :options="chartOptions" data-position="above" data-color="rgba(0, 0, 0, 1)" />
        </div>
      </v-col>
      <v-col xl="6" lg="12" md="12" sm="12">
        <v-data-table :headers="tableHeaders" :options="tableOptions" :items="tableItems" :loading="loading" :hide-default-footer="true" />
      </v-col>
    </v-row>
    <v-row v-else>
      {{ $t('report.noresult') }}
    </v-row>
  </v-container>
</template>

<script>
  import { getFirstDayOfYearDate, getTodayDate } from '@/util/DateUtil';
  import { COLOR_RANDOM } from '@/util/ColorUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';
  import LocationSelector from '@/views/components/common/LocationSelector';
  import BarChart from '@/views/components/common/chart/BarChart';

  export default {
    name: 'MetaCustomerOrderAnalytics',
    components: { LocationSelector, PeriodSelector, BarChart },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getFirstDayOfYearDate(-4),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        locations: null,
        dataCollection: {},
        chartOptions: {
          title: {
            display: false
          },
          legend: {
            display: true,
            position: 'bottom',
            align: 'center'
          },
          tooltips: {
            mode: 'index',
            intersect: false
          },
          responsive: true,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                min: 0
              }
            }]
          }
        },
        tableHeaders: [],
        tableOptions: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        tableItems: []
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null || this.locations === null) {
          return;
        }

        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/meta/customer/order?from='
            + encodeURIComponent(this.startFullDate) + '&to=' + encodeURIComponent(this.endFullDate) + '&locations=' + String(this.locations), { timeout: 60000 });
          if (typeof response.data === 'object') {
            this.dataCollection = {
              labels: Object.keys(response.data[Object.keys(response.data)[0]]),
              datasets: Object.keys(response.data).map((period) => {
                return { label: period, backgroundColor: COLOR_RANDOM[(Number(period) % 2020)], stack: period, data: Object.values(response.data[period]) };
              })
            };

            this.tableHeaders = [{
              text: this.$i18n.t('metaReport.metaCrm.customerOrder.table.column.year'),
              align: 'left',
              filterable: false,
              sortable: true,
              value: 'year'
            }];
            this.dataCollection.labels.forEach((label, i) => {
              this.tableHeaders.push({
                text: label,
                align: 'center',
                filterable: false,
                sortable: true,
                value: String(i)
              });
            });

            this.tableItems = [];
            this.dataCollection.datasets.forEach((dataset) => {
              const item = {
                year: dataset.label
              };
              const total = dataset.data.reduce((total, data) => total + data, 0);
              if (total > 0) {
                dataset.data.forEach((data, i) => {
                  const percent = Math.round(Number(data) / total * 100);
                  item[i] = data + ' (' + String(percent) + '%)';
                });
              }
              this.tableItems.push(item);
            });
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('metaReport.error'));
        }
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      },
      async updateLocations (locationKeys) {
        this.locations = locationKeys.join(',');
        await this.refreshData();
      }
    }
  };
</script>
