var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"meta-customers-by-location"}},[_c('period-selector',{attrs:{"start-date-prop":_vm.startDate,"start-hour-prop":_vm.startHour,"end-date-prop":_vm.endDate,"end-hour-prop":_vm.endHour,"max-days":1800,"loading":_vm.loading},on:{"update":_vm.updatePeriod}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.items,"loading":_vm.loading,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.location",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.location.name)+" ")]}},{key:"item.inactive",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayResult(item.inactive, item.total))+" ")]}},{key:"item.new",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayResult(item.new, item.total))+" ")]}},{key:"item.occasional",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayResult(item.occasional, item.total))+" ")]}},{key:"item.vip",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayResult(item.vip, item.total))+" ")]}}],null,true)})],1)],1),_vm._l((_vm.$t('metaReport.metaCrm.customersByLocation.definitions')),function(definition){return _c('p',{key:definition.rank,staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(definition.rank))]),_vm._v(" : "+_vm._s(definition.description)+" ")])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }