<template>
  <v-container id="meta-customer-unique">
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :max-days="1800" :loading="loading" @update="updatePeriod" />
    <location-selector :loading="loading" @update="updateLocations" />
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="tableHeaders" :options="tableOptions" :items="tableItems" :loading="loading" :hide-default-footer="true">
          <template #[`item.month`]="{ item }">
            {{ MONTH[item.month] }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { MONTH, getFirstDayOfYearDate, getTodayDate } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';
  import LocationSelector from '@/views/components/common/LocationSelector';

  export default {
    name: 'MetaCustomerAcquisitionAnalytics',
    components: { LocationSelector, PeriodSelector },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        MONTH: MONTH,
        startDate: getFirstDayOfYearDate(-4),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        locations: null,
        tableHeaders: [{
          text: this.$i18n.t('metaReport.metaCrm.customerAcquisition.table.column.year'),
          align: 'left',
          filterable: false,
          sortable: true,
          value: 'year'
        }, {
          text: this.$i18n.t('metaReport.metaCrm.customerAcquisition.table.column.month'),
          align: 'left',
          filterable: false,
          sortable: true,
          value: 'month'
        }, {
          text: this.$i18n.t('metaReport.metaCrm.customerAcquisition.table.column.searchEngine'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'searchEngine'
        }, {
          text: this.$i18n.t('metaReport.metaCrm.customerAcquisition.table.column.listing'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'listing'
        }, {
          text: this.$i18n.t('metaReport.metaCrm.customerAcquisition.table.column.socialMedia'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'socialMedia'
        }, {
          text: this.$i18n.t('metaReport.metaCrm.customerAcquisition.table.column.platform'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'platform'
        }, {
          text: this.$i18n.t('metaReport.metaCrm.customerAcquisition.table.column.socialMedia'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'socialMedia'
        }, {
          text: this.$i18n.t('metaReport.metaCrm.customerAcquisition.table.column.friend'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'friend'
        }, {
          text: this.$i18n.t('metaReport.metaCrm.customerAcquisition.table.column.flyer'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'flyer'
        }, {
          text: this.$i18n.t('metaReport.metaCrm.customerAcquisition.table.column.brand'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'brand'
        }, {
          text: this.$i18n.t('metaReport.metaCrm.customerAcquisition.table.column.unknown'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'unknown'
        }, {
          text: this.$i18n.t('metaReport.metaCrm.customerAcquisition.table.column.other'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'other'
        }],
        tableOptions: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        tableItems: []
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null || this.locations === null) {
          return;
        }

        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/meta/customer/acquisition?from='
            + encodeURIComponent(this.startFullDate) + '&to=' + encodeURIComponent(this.endFullDate) + '&locations=' + String(this.locations), { timeout: 60000 });
          if (Array.isArray(response.data)) {
            this.tableItems = response.data.map((data) => {
              const result = data;
              const total = Object.keys(data).reduce((total, key) => {
                if (!['year', 'month'].includes(key)) {
                  return total + data[key];
                }

                return total;
              }, 0);
              Object.keys(data).forEach((key) => {
                if (!['year', 'month'].includes(key) && total > 0) {
                  const percent = Math.round(Number(data[key]) / total * 100);
                  data[key] += ' (' + String(percent) + '%)';
                }
              });

              return result;
            });
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('metaReport.error'));
        }
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      },
      async updateLocations (locationKeys) {
        this.locations = locationKeys.join(',');
        await this.refreshData();
      }
    }
  };
</script>
