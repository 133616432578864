<template>
  <v-container id="meta-customers-by-location">
    <period-selector :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :max-days="1800" :loading="loading" @update="updatePeriod" />
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :options="options" :items="items" :loading="loading" :hide-default-footer="true">
          <template #[`item.location`]="{ item }">
            {{ item.location.name }}
          </template>
          <template #[`item.inactive`]="{ item }">
            {{ displayResult(item.inactive, item.total) }}
          </template>
          <template #[`item.new`]="{ item }">
            {{ displayResult(item.new, item.total) }}
          </template>
          <template #[`item.occasional`]="{ item }">
            {{ displayResult(item.occasional, item.total) }}
          </template>
          <template #[`item.vip`]="{ item }">
            {{ displayResult(item.vip, item.total) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <p v-for="definition in $t('metaReport.metaCrm.customersByLocation.definitions')" :key="definition.rank" class="text-center">
      <strong>{{ definition.rank }}</strong> : {{ definition.description }}
    </p>
  </v-container>
</template>

<script>
  import { getFirstDayOfYearDate, getTodayDate } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';

  export default {
    name: 'MetaCustomersByLocationAnalytics',
    components: { PeriodSelector },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        startDate: getFirstDayOfYearDate(-4),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        headers: [
          {
            text: this.$i18n.t('metaReport.metaCrm.customersByLocation.table.column.location'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'location'
          },
          {
            text: this.$i18n.t('metaReport.metaCrm.customersByLocation.table.column.total'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'total'
          },
          {
            text: this.$i18n.t('metaReport.metaCrm.customersByLocation.table.column.inactive'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'inactive'
          },
          {
            text: this.$i18n.t('metaReport.metaCrm.customersByLocation.table.column.new'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'new'
          },
          {
            text: this.$i18n.t('metaReport.metaCrm.customersByLocation.table.column.occasional'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'occasional'
          },
          {
            text: this.$i18n.t('metaReport.metaCrm.customersByLocation.table.column.vip'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'vip'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['location.id'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        items: []
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      displayResult (result, total) {
        return String(result) + ' (' + String(Math.round((result / total) * 10000) / 100) + '%)';
      },
      async refreshData () {
        if (this.startFullDate === null || this.endFullDate === null) {
          return;
        }

        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/meta/customer/location?from='
            + encodeURIComponent(this.startFullDate) + '&to=' + encodeURIComponent(this.endFullDate), { timeout: 60000 });
          if (response.data !== undefined) {
            this.items = Object.values(response.data);
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('metaReport.error'));
        }
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;
        await this.refreshData();
      }
    }
  };
</script>
