<template>
  <v-row dense class="text-center my-0" justify="center">
    <v-col cols="2" />
    <v-col :cols="submit==='manual' ? 6 : 8">
      <v-select v-model="selection" :items="locations" item-value="key" item-text="city" required multiple chips dense persistent-hint
                :label="$t('common.location.label.multiple')" :clearable="true" @change="update()"
      >
        <template #prepend-item>
          <v-list-item ripple @mousedown.prevent @click="toggle">
            <v-list-item-action>
              <v-icon :color="selection.length > 0 ? (selection.length === locations.length ? 'accent' : 'primary') : ''">
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('common.location.button.all') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2" />
        </template>
      </v-select>
    </v-col>
    <v-col v-if="submit==='manual'" cols="2">
      <v-btn big color="senary" :loading="loading" @click.stop="update(true)">
        {{ $t('common.location.button.submit') }}
      </v-btn>
    </v-col>
    <v-col cols="2" />
  </v-row>
</template>

<script>
  export default {
    name: 'LocationSelector',
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      submit: {
        type: String,
        default: 'auto'
      }
    },
    data () {
      return {
        locations: [],
        selection: []
      };
    },
    computed: {
      icon () {
        if (this.selection.length === this.locations.length) return 'mdi-close-box';
        if (this.selection.length > 0) return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
      }
    },
    mounted: async function () {
      if (Array.isArray(this.$store.state.locations)) {
        this.locations = this.$store.state.locations;
        this.selection = this.locations.map((location) => location.key);
      } else if (typeof this.$store.state.location === 'object') {
        this.locations = [this.$store.state.location];
        this.selection = [this.locations[0].key];
      }
      if (this.submit === 'auto') {
        this.update();
      }
    },
    methods: {
      toggle () {
        this.$nextTick(() => {
          if (this.selection.length === 0) {
            this.selection = this.locations.map((location) => location.key);
          } else {
            this.selection = [];
          }
          this.update();
        });
      },
      update (force) {
        if (force || this.submit === 'auto') {
          this.$emit('update', this.selection);
        }
      }
    }
  };
</script>
